<template>
  <div>
	 
    <layout>
		 
      <template #left>
        <div class="flexbox a-center title">
          <img class="smile-icon" src="@/assets/img/logo2.png" alt="" />
          <div class="ml5" style="align-self: flex-end">
            <span class="f32">{{ info.mobile }} </span>
            <span class="f22 ml5">ID: {{ info.id }}</span>
          </div>
        </div>
      </template>
	  
      <template #title></template>
	   
      <template #right>
		  <img
		    class="msg-icon" style="width: 28px;height: 28px; position: absolute; left: -50px;"
		    src="@/assets/lang2.png"
		    alt=""
		    
		   @click="changlang()"
		  />
				<img
				  class="msg-icon" style="width: 28px;height: 28px; position: absolute; left: -20px;"
				  src="@/assets/app.png"
				  alt=""
				  
				 @click="downapp()"
				/>
        <img
          class="msg-icon"
          :src="icon.msg"
          alt=""
          @click="$router.push('/leaveMessage')"
        />
				
        <van-badge :content="info.msgcount" color="red" />
      </template>
	 
      <template #content>
        <div class="content">
          <div class="notice flexbox ml32 mr32 a-center mt32">
            <img class="tz-icon ml32" :src="icon.tz" alt="" />
            
			<marquee behavior="down" scrolldelay="100" class="ml32 bcolor" style="color: #fff;"> 
			{{$t('恭喜新用户')}}{{str}}  |
			{{$t('恭喜新用户')}}{{str2}}  |  
			{{$t('恭喜新用户')}}{{str3}}  |
			</marquee>
          </div>
          <div class="line mt24 ml32 mr32 mb14"></div>
          <div class="mt20" @click="qianyuebuy()">
            <van-swipe class="banner ml32 mr32" :autoplay="3000">
              <van-swipe-item v-for="(item, index) in bannerList" :key="index">
                <img class="img-banner" :src="item" alt="" />
              </van-swipe-item>
            </van-swipe>
          </div>
          <div class="flex_row_space-between about mb20 mt30">
            <div class="ml32 flexbox a-center"></div>
          </div>
          <div class="mt22 nav-bg ml14 mr14">
            <div class="nav flexbox">
              <div
                class="nav-item flexbox_column a-center"
                v-for="(item, i) in navList"
                :key="i"
                @click="handleTo(item.url)"
              >
                <img class="img-icon" :src="item.img" alt="" />
                <div class="mt5">{{ item.title }}</div>
              </div>
            </div>
            <div class="line mt24 ml32 mr32 mb14"></div>
            <div class="flex_row_space-between about mb20" @click="about()">
              <div class="ml32 flexbox a-center">
                <div class="child mr14" />
                {{ $t('点击去了解我们') }}
                <img
                  class="jiantou-w ml20"
                  :src="require('@/assets/icon/jiantou-w.png')"
                  alt=""
                />
              </div> 
             <div class="mr32">
                <img
                  class="about"
                  :src="require('@/assets/icon/about.png')"
                  alt=""
                />
              </div> 
            </div>
			 <!-- <div class="ml14 mr14 f" style="font-size: 14px;color: red;" @click="changeL">临时测试按钮点击切换语言</div> -->
            <div class="earnings flex_row_space-between mt34">
              <div class="ml32 flexbox a-center">
                <span class="f46">{{ (geren.geren_amount *  1).toFixed(2) }} USDT</span>
                <span class="f24 ml5">{{ $t('今日收益') }}</span>
              </div>
              <!--<div class="mr32 f46">
                ≈<span style="font-size: 10px;">Mex$</span>{{ (geren.geren_amount * info.rate).toFixed(2) }}
              </div>-->
            </div>
            <div class="line ml32 mr32 mt26"></div>
            <div class="ml32 mr32 mt24 market flexbox">
              <div
                class="market-item flexbox_column a-cnter"
                v-for="(item, i) in HQlist"
                :key="i"
              >
                <div class="flexbox ccolor f26">
                  {{ item.name }}
                </div>
                <div
                  class="flexbox f40 mt5"
                  :class="[item.isUp > 0 ? 'up' : 'down']"
                >
                  {{ Number(item.last).toFixed(2) }}
                </div>
                <div
                  class="flexbox f26 mt5"
                  :class="[item.isUp > 0 ? 'up' : 'down']"
                >
                  {{ item.change }} %
                </div>
                <!-- <img
                  class="img-updown"
                  :src="
                    item.isUp > 0
                      ? require('@/assets/icon/up.png')
                      : require('@/assets/icon/down.png')
                  "
                  alt=""
                /> -->
				<br>
              </div>
            </div>
          </div>
		  <br>
		  <div
		    class="robot ml14 mr14 flex_row_space-between"
		    @click="handleTo('/serveWay')"
		  >
		    <div class="flexbox a-center">
		      <img
		        class="robot-img ml34"
		        :src="require('@/assets/img/tether.png')"
		        alt=""
		      />
		      <div class="ml34">
		        <div class="f32">{{ $t('快捷充币') }}</div>
		        <div class="ccolor mt10">{{ $t('快速买USDT') }}</div>
		      </div>
		    </div>
		    <div>
		      <img
		        class="jiantou"
		        :src="require('@/assets/icon/jiantou.png')"
		        alt=""
		      />
		    </div>
		  </div>
          <div class="laste mt20 ml14 mr14">
            <div class="ml32 mr32 flex_row_space-between">
              <div class="f30">{{ $t('购买教程') }}</div>
              <div @click="handleRobot">
                <span class="ccolor mr14">{{ $t('更多') }}</span>
                <img
                  class="jiantou"
                  :src="require('@/assets/icon/jiantou.png')"
                  alt=""
                />
              </div>
            </div>
            <div class="mt30">
              <van-swipe :loop="false" :width="305" :show-indicators="false">
                <van-swipe-item v-for="item in aituring" :key="item.name">
                  <div class="flex_row_space-between">
                    <div class="flexbox a-center">
                      <img
                        class="robot-w"
                        :src="require('@/assets/img/logo2.png')"
                        alt=""
                      />
                      <div class="f30 ml20" style="color: #272728">
                        {{ $t('机器人性能') }}
                      </div>
                    </div>
                    <div>{{ item.name }}</div>
                  </div>
                  <div class="flexbox j-center mt20">
                    <div>
                      <div class="f18">
                        {{ item.price }} USDT <span style="font-size: 12px;"></span>
<!--{{ (item.price * info.rate).toFixed(2) }}-->
                      </div>
                      <div class="mt5">{{ $t('机器人价格') }}</div>
                    </div>
                    <div style="width: 50px"></div>
                    <div>
                      <div class="f18">
                        {{ item.dan_price }} USDT <span style="font-size: 12px;"></span><!--{{
                          (item.dan_price * info.rate).toFixed(2)
                        }}-->
                      </div>
                      <div class="mt5">{{ $t('激活金额') }}</div>
                    </div>
                  </div>
                  <div class="buy flex_row_space-between">
                    <div class="ml32">
                      <span class="ccolor">{{ $t('有效期') }}:</span>
                      <span>{{ item.gq_day }} {{ $t('天') }}</span><br>
                      <span class="ml5 cclor">{{ $t('日收益') }} :</span>
                      <span>{{ (item.earnings)}} USDT</span>
					  <br>
					  <span class="ccolor"> {{ $t('Los puntos') }} :+{{item.zs_jyzhi}} </span><br>
                    </div>
                    <div class="btn mr32 bcolor" @click="handleRobot2(item, 1)">
                      {{ $t('去购买') }}
                    </div>
                  </div>
                </van-swipe-item>
                <van-swipe-item style="display: none"></van-swipe-item>
              </van-swipe>
            </div>
            <div class="mt30 hide partner">
              <div class="ml32 f30">{{ $t('我们的合作伙伴') }}</div>
              <div class="mt20 ml32 mr32 flexbox flex-wrap">
                <div
                  class="partner-item flexbox center"
                  v-for="(item, i) in partnerList"
                  :key="i"
                  @click="handleUrl(item.url)"
                >
                  <img class="imgIcon" :src="item.icon" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </layout>
   
    <ROBOT />
	
	<popup center ref="popup" >
		
		
	  <div class="poptip">
	    <img class="imgs" src="../../assets/img/vipxuzhi.png" alt="" />
	    <div class="ct">
	      <div
	        style="
	          color: #ffffff;
	          position: absolute;
	          top: 0.4rem;
	          right: 0.5rem;
	        "
	        @click="closemsg()"
	      >
	        X
	      </div>
	      <div class="text" v-html="info.text"></div>
	    </div>
	  </div>
	</popup>
  </div>
</template>

<script>
import {
  apiHangqing,
  apiRobots,
  apiUser_mas2,
  apibuyrobots,
  apimyrobots,
  apiwenzhang,
  getMoble,
  apimyshouyi,
  apizsrobots,
  apifeerobot,
  getjiqinum
} from '@/http/api/'
import popup from '@/components/popup/index.vue'
import pako from 'pako'
import ROBOT from '@/components/robot'
import { mapGetters } from 'vuex'
import {
  Button,
  Badge,
  NoticeBar,
  Swipe,
  SwipeItem,
  Col,
  Row,
  Popup,
  Cell,
  Field
} from 'vant'
export default {
  components: {
    popup,
    ROBOT,
    [Badge.name]: Badge,
    [NoticeBar.name]: NoticeBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Button.name]: Button,
    [Col.name]: Col,
    [Row.name]: Row,
   
    [Cell.name]: Cell,
    [Field.name]: Field
  },
  data() {
    return {
      icon: {
        smile: require('@/assets/icon/smile-icon.png'),
        msg: require('@/assets/icon/bell-icon.png'),
        tz: require('@/assets/icon/tz.png')
      },
      bannerList: [require('@/assets/img/trq1.png'),require('@/assets/img/trq.png'),require('@/assets/img/YT.png'),require('@/assets/img/tm.png'),require('@/assets/img/58.jpg'),require('@/assets/img/173.jpg')],
      show: true,
      btc: '',
      fil: '',
      eth: '',
      token: '',
      info: [],
      iosdown: '',
      anddown: '',
      geren: '',
      aituring: [],
      HQlist: [],
      msgtz: 0,
      lingai: [],
	  str:"",
	  str2:"",
	  str3:""
    }
  },
  computed: {
    ...mapGetters(['partnerList']),
    navList() {
      return [
     
        {
          title: this.$i18n.t('navbar.提现'),
          img: require('@/assets/icon/nav2.png'),
          url: '/drawings'
        },
        {
          title: this.$i18n.t('navbar.帮助'),
          img: require('@/assets/icon/nav3.png'),
          url: '/helpCenter'
        },
        {
          title: this.$i18n.t('navbar.分享'),
          img: require('@/assets/icon/nav4.png'),
          url: '/joinUs'
        },
		
        {
          title: this.$i18n.t('navbar.活动'),
          img: require('@/assets/icon/nav5.png'),
          url: '/actionCenter'
        },
		{
		  title: this.$i18n.t('Bono'),
		  img: require('@/assets/icon/nav1.png'),
		  url: '/huodong'
		},
      ]
    }
  },
  created() {
    var token = localStorage.getItem('key')

    if (!token) {
      this.$router.replace('/login')
    }
    this.msgtz = localStorage.getItem('msg')
	
    this.token = token
    apiUser_mas2({
      userid: token
    }).then((res) => {
      // 未登录踢下线
      if (res.status == 10) {
        this.$router.replace('/login')
      }
      // this.showdown = res.user.showdown
      this.info = res.user
      this.rate = res.user.rate
      this.iosdown = res.user.iosdown
      this.anddown = res.user.anddown
    })
    apiRobots({}).then((res) => {
      this.aituring = res.info
    })
    apimyrobots({ userid: token }).then((res) => {
      this.myaicount = res.info.length
    })
    apimyshouyi({ userid: token }).then((res) => {
      this.geren = res.info
      
      this.geren.geren_amount = res.info.geren_amount
     
    })
    // 0号机器人
    apizsrobots({ userid: token }).then((res) => {
      this.lingai = res.info
    })
  },
  mounted() {
    this.getHQ()
	if(this.msgtz == 1){
		this.$refs.popup.show()
	}
    this.homeNotice();
    console.log(this.HQlist)
  },
  methods: {
	  changlang(){
		  this.$router.push('/lang')
	  },
	  homeNotice(){
			var tell = /(\d{2})\d*(\d{4})/;
				let jiqiNum = Math.floor(Math.random()*5+1);
				jiqiNum = this.getjiqinum(jiqiNum);
				let telNum = getMoble();
				telNum = telNum.replace(tell, '4****$2')
				
				this.str = telNum+this.$t('升级')+jiqiNum;
				
				  jiqiNum = Math.floor(Math.random()*5+1);
				  jiqiNum = this.getjiqinum(jiqiNum);
				  telNum = getMoble();
				  telNum = telNum.replace(tell, '4****$2')
				this.str2 = telNum+this.$t("升级")+jiqiNum;
				
				 jiqiNum = Math.floor(Math.random()*5+1);
				 jiqiNum = this.getjiqinum(jiqiNum);
				 telNum = getMoble();
				 telNum = telNum.replace(tell, '4****$2')
				this.str3 = telNum+this.$t("升级")+jiqiNum;
				
				this.str3 = this.str+this.str3
	  },
	  // 转换机器人
	  	getjiqinum(num){
	  			
	  			if(num==0){
	  				return this.$t('No.0');
	  			}else if(num == 1){
	  				return this.$t('No.1');
	  			}else if(num == 2){
	  				return this.$t('No.2');
	  			}else if(num == 3){
	  				return this.$t('No.3');
	  			}else if(num == 4){
	  				return this.$t('No.4');
	  			}else if(num == 5){
	  				return this.$t('No.5');
	  			}
	  	},
    qianyuebuy() {
      this.$router.push({
        name: 'myRobot',
        query: {
          info: this.lingai,
          type: 100
        }
      })
    },
    closemsg() {
      this.msgtz = 0;
	  this.$refs.popup.hide()
      window.localStorage.removeItem('msg')
    },
    about() {
      window.location.href = 'https://web.plus500at.com/'
    },
    getHQ() {
      var that = this
      apiHangqing({}).then((res) => {
        res.forEach(function (item, index) {
          if (item.name == 'BTC' || item.name == 'ETH' || item.name == 'XCH') {
            that.HQlist.push(item)
          }
        })
        this.btc = res[0].last
        this.fil = res[1].last
        this.xch = res[2].last
        this.doge = res[3].last
        this.bch = res[4].last
        this.zec = res[5].last
        this.eth = res[6].last
        this.eos = res[7].last
        this.ltc = res[8].last
        this.etc = res[9].last
        this.sendSocket()
      })
    },
    sendSocket() {
      let t = this
      this.socket = new WebSocket('wss://api-aws.huobi.pro/ws')
      this.socket.onopen = this.open
      // 监听socket消息
      this.socket.onmessage = this.getMessage
    },
    open: function () {
      // console.log("socket连接成功");
      let arr = [
        'btcusdt',
        'filusdt',
        'xchusdt',
        'dogeusdt',
        'bchusdt',
        'zecusdt',
        'ethusdt',
        'eosusdt',
        'ltcusdt',
        'etcusdt'
      ]
      var _that = this.socket
      arr.forEach(function (item, index) {
        let subK = {
          // 订阅数据
          sub: 'market.' + item + '.kline.1min',
          id: item
        }
        _that.send(JSON.stringify(subK))
      })
    },
    getMessage: function (event) {
      let blob = event.data
      let reader = new FileReader()
      var _that = this
      reader.onload = function (e) {
        let ploydata = new Uint8Array(e.target.result)
        let msg = pako.inflate(ploydata, {
          to: 'string'
        })
        msg = JSON.parse(msg)
        //console.log(msg);
        // _that.lists = [];
        let name = ''
        let fu = ''
        let bili = '0.00'
        let color = ''
        let home = ''
        let zhi = ''
        let isUp = false
        if (msg.ch) {
          if (msg.ch == 'market.btcusdt.kline.1min') {
            name = 'BTC（USDT)'

            // 计算比例
            if (msg.tick.close >= _that.btc) {
              fu = '+'
              bili = ((msg.tick.close - _that.btc) / _that.btc) * 100
              bili = Math.floor(bili * 100) / 100
              isUp = false
            } else {
              fu = '-'
              bili = ((_that.btc - msg.tick.close) / _that.btc) * 100
              bili = Math.floor(bili * 100) / 100
              isUp = true
            }

            // _that.lists[0] = {'id':1,'name': name,'val':msg.tick.vol,'change': fu+bili,'cny': 0, 'color': color, 'home': home, 'zhi': zhi};

            _that.HQlist[0].name = name
            _that.HQlist[0].val = msg.tick.vol
            _that.HQlist[0].change = fu + bili
            _that.HQlist[0].isUp = isUp
            _that.HQlist[0].last = msg.tick.close
          }
          if (msg.ch == 'market.filusdt.kline.1min') {
            name = 'FIL(USDT)'
            // 计算比例
            if (msg.tick.close >= _that.fil) {
              fu = '+'
              bili = ((msg.tick.close - _that.fil) / _that.fil) * 100
              bili = Math.floor(bili * 100) / 100
              isUp = false
            } else {
              fu = '-'
              bili = ((_that.fil - msg.tick.close) / _that.fil) * 100
              bili = Math.floor(bili * 100) / 100
              isUp = true
            }
            // _that.lists[1] = {'id':2,'name': name,'val':msg.tick.vol,'change': fu+bili,'cny': 0, 'color': color, 'home': home, 'zhi': zhi};
            _that.HQlist[1].name = name
            _that.HQlist[1].val = msg.tick.vol
            _that.HQlist[1].change = fu + bili
            _that.HQlist[1].last = msg.tick.close
            _that.HQlist[1].isUp = isUp
          }

          if (msg.ch == 'market.ethusdt.kline.1min') {
            name = 'ETH(USDT)'
            // 计算比例
            if (msg.tick.close >= _that.eth) {
              fu = '+'
              bili = ((msg.tick.close - _that.eth) / _that.eth) * 100
              bili = Math.floor(bili * 100) / 100
              isUp = false
            } else {
              fu = '-'
              bili = ((_that.eth - msg.tick.close) / _that.eth) * 100
              bili = Math.floor(bili * 100) / 100
              isUp = true
            }
            // _that.lists[2] = {'id':3,'name': name,'val':msg.tick.vol,'change': fu+bili,'cny': 0, 'color': color, 'home': home, 'zhi': zhi};
            _that.HQlist[2].name = name
            _that.HQlist[2].val = msg.tick.vol
            _that.HQlist[2].change = fu + bili
            _that.HQlist[2].isUp = isUp
            _that.HQlist[2].last = msg.tick.close
          }
        }
      }
      reader.readAsArrayBuffer(blob, 'utf-8')

      var timea = Math.round(new Date() / 1000)

      // console.log(this.HQlist);
      this.$forceUpdate()
      var _thats = this.socket
      setTimeout(function () {
        _thats.send(
          JSON.stringify({
            pong: timea
          })
        )
      }, 4000)
    },

    send: function () {
      var timea = Math.round(new Date() / 1000)
      var data = {
        pong: timea
      }
      console.log(data)
      this.socket.send(data)
    },
    downapp() {
      const u = navigator.userAgent
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (isiOS) {
        window.location.href = "https://plus500at.com/app.mobileconfig";
      } else {
        window.location.href = "https://plus500at.com/app.apk";
      }
    },
    handleUrl(url) {
      window.location.href = url
    },
    // 切换语言
    changeL() {
      this.$i18n.locale = 'zh'
    },
    handleTo(url) {
      this.$router.push(url)
    },
    handleRobot() {
      this.$router.push('/robot')
    },
    // 购买
    handleRobot2(info, type) {
      this.$router.push({
        name: 'robot',
        query: {
          info: info,
          type: type
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.smile-icon {
  width: 70px;
  height: 70px;
}
.title {
  line-height: 80px;
}
.msg-icon {
  width: 26px;
  height: 38px;
}
.content {
  .notice {
    height: 58px;
    background: rgb(77, 77, 77);
    border-radius: 29px 29px 29px 29px;
	 color: #fff;
    .tz-icon {
      width: 19px;
      height: 28px;
    }
  }
  .banner {
    height: 340px;
    .img-banner {
      width: 100%;
      height: 100%;
    }
    .van-swipe-item {
      color: #fff;
      font-size: 20px;
      /* height: 100%; */
      text-align: center;
    }
  }
  .nav-bg {
    margin-top: 20px;
    background-color: rgb(77, 77, 77) !important;
    padding-top: 50px;
    border-radius: 59px;
   
	
    .nav {
      &-item {
        .img-icon {
          width: 120px;
          height: 120px;
        }
        width: 25%;
      }
    }
  }
  .about {
    .child {
      width: 20px;
      height: 20px;
      background: #0d9135;
      border-radius: 50%;
    }
    .jiantou-w {
      width: 28px;
    }
    .about {
      width: 300px;
      height: 150px;
    }
  }
  .robot {
    height: 128px;
    background-color: #4D4D4D;
    border-radius: 20px;
    .robot-img {
      width: 120px;
      height: 120px;
    }
    .jiantou {
      width: 34px;
      margin-right: 40px;
    }
  }
  .market {
    .market-item {
      width: 33.33%;
      padding: 0 10px;
      .img-updown {
        width: 162px;
        height: 55px;
        margin-top: 15px;
      }
      .up {
        color: #ff3a53;
      }
      .down {
        color: #32ba8d;
      }
    }
  }
  .laste {
    background-color: rgb(77, 77, 77);
	border-radius: 59px;
    padding: 20px 0;
    .van-swipe {
      /* height: 337px; */
      .van-swipe-item {
        padding: 20px;
        /* width: 405px; */
        /* height: 337px; */
        margin-left: 32px;
        background: linear-gradient(
          165deg,
          #0d9135 0%,
          rgba(255, 217, 89, 0) 100%
        );
		//background: linear-gradient(1deg,#ffcc8f,#ffedd8);
        box-shadow: 0px 19px 26px 1px rgba(0, 0, 0, 0.5);
        border-radius: 20px;
        opacity: 1;
        .robot-w {
          width: 79px;
          height: 79px;
        }
        .buy {
          background-color: #3a372d;
          height: 100px;
          border-radius: 50px;
          margin-top: 20px;
          .btn {
            height: 60px;
            padding: 0 30px;
            background-color: #44b361;
            border-radius: 30px;
            line-height: 60px;
          }
        }
      }
    }
    .jiantou {
      width: 32px;
    }
  }
  .partner {
    background-color: rgb(77, 77, 77);
	border-radius: 59px;
    .partner-item {
      width: 32%;
      margin-bottom: 20px;
      background-color: #2a2c2e;
      height: 58px;
      border-radius: 29px;
      margin-left: 3px;
      .imgIcon {
        width: 154px;
        height: 40px;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  // .bg {
  //   height: 20px;
  //   background-size: cover;
  //   background: url('../../assets/img/会员须知.png') no-repeat;
  // }
  .poptip {
    margin-left: 32px;
    margin-right: 32px;
    // height: 442px;
    background: #2a2c2e;
    position: relative;
    // padding: 20px 30px;
    border-radius: 28px;
    .ct {
      padding: 0px 30px 20px;
      .text {
        color: #fff;
        line-height: 50px;
      }
    }
    .imgs {
      width: 100%;
      height: 250px;
      // position: absolute;
      // left: 0;
      // top: 0;
      // transform: translate(-0%, -50%);
    }
  }
}
</style>
